import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Tags from '../components/tags'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')

    return (
      <Layout>
      <div className='blog-single'>
          <Seo
            title={post.title}
            description={post.description.childMarkdownRemark.excerpt}
            image={`http:${post.heroImage.resize.src}`}
          />
          <Hero
            image={post.heroImage?.gatsbyImageData}
            title={post.title}
            content={post.description?.childMarkdownRemark?.excerpt}
          />
          <div className="container">
            <span className="meta">
              <time dateTime={post.rawDate}>{post.publishDate}</time> 
            </span>
            <div className="single-article">
              <div
                className="single-body"
                dangerouslySetInnerHTML={{
                  __html: post.body?.childMarkdownRemark?.html,
                }}
              />
              <Tags tags={post.tags} />
            </div>
          </div>
      </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      author {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      tags
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
